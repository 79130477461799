import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './components/landing_page';
import CampActivities from './components/camp_activities';
import { Route, Routes } from "react-router-dom"; // Removed Router
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/camp-activities" element={<CampActivities />} />
    </Routes>
  );
}

export default App;
