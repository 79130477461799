import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import PropTypes from "prop-types";
import axios from "axios";

function StepTwoForm({
  getTotalAmount,
  bedOption, // Access bedOption as a prop
  handleBack,
  handlePaymentSuccess,
  handlePaymentClose,
  isSubmitting,
  email,
  phoneNumber,
  customerName,
  publicKey,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [verificationError, setVerificationError] = useState(null); // For displaying any verification errors
  const amount = getTotalAmount();

  // Flutterwave configuration
  const config = {
    public_key: publicKey,
    tx_ref: `${new Date().getTime()}-${Math.floor(Math.random() * 1000000)}`,
    amount: amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: email,
      phone_number: phoneNumber,
      name: customerName || "John Doe",
    },
    customizations: {
      title: "CGWC National Youth Fellowship Registration",
      description: "Registration for 2024 CGWC Youth Camp",
      logo: "https://cgwc-youth-fellowship.vercel.app/static/media/cgwc-logo.e8cbe010260cca764e57.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const handlePayment = () => {
    handleFlutterPayment({
      callback: (response) => {
        handlePaymentSuccess(response);
        closePaymentModal();
        setIsProcessing(false); // Reset to false after success
      },
      onClose: () => {
        handlePaymentClose();
        setIsProcessing(false); // Reset to false if the modal is closed
      },
    });
  };

  const verifyRegistration = async () => {
    setIsProcessing(true); // Start processing
    setVerificationError(null); // Clear any previous error
    try {
      const response = await axios.post(
        "https://cgwc-youth-fellowship-backend-node.onrender.com/youthFellowship/v1/verify-registration-payment",
        {
          bed_option: bedOption, // Use the bedOption prop
          paymentAmount: amount,
        }
      );

      if (response.data.success) {
        handlePayment(); // Proceed to payment if verification is successful
      } else {
        setVerificationError(response.data.message || "Verification failed.");
        setIsProcessing(false); // Stop processing on failure
      }
    } catch (error) {
      setVerificationError("An error occurred during verification.");
      setIsProcessing(false); // Stop processing on error
    }
  };

  return (
    <>
      <Form.Group controlId="paymentAmountDisplay" className="mb-3">
        <Form.Label>Total Payment Amount</Form.Label>
        <Form.Control type="text" value={`₦${amount}`} readOnly plaintext />
      </Form.Group>

      {verificationError && (
        <div className="text-danger mb-3">{verificationError}</div>
      )}

      <div className="d-flex justify-content-between">
        <Button
          variant="secondary"
          onClick={handleBack}
          disabled={isSubmitting || isProcessing}
        >
          Back
        </Button>

        <Button
          variant="success"
          onClick={verifyRegistration} // Start verification on button click
          disabled={isSubmitting || isProcessing} // Disable if submitting or processing
        >
          {isProcessing ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Processing...
            </>
          ) : (
            "Pay Now"
          )}
        </Button>
      </div>
    </>
  );
}

// Prop type validation
StepTwoForm.propTypes = {
  getTotalAmount: PropTypes.func.isRequired,
  bedOption: PropTypes.string.isRequired, // Added bedOption prop validation
  handleBack: PropTypes.func.isRequired,
  handlePaymentSuccess: PropTypes.func.isRequired,
  handlePaymentClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  publicKey: PropTypes.string.isRequired,
};

export default StepTwoForm;
