// src/components/EventRegistrationForm.jsx

import React, { useMemo, useEffect, useState } from "react";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { sanitizeInput } from "../services/sanitize";
import StepOneForm from "./StepOneForm";
import StepTwoForm from "./StepTwoForm";
import ErrorAlert from "./ErrorAlert";
import { memberAreas } from "../services/area";
import useFormStore from "../store/formStore";
import { Clipboard, Download } from "react-bootstrap-icons";
import he from "he";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

function EventRegistrationForm({ event, onClose }) {
  const {
    formData,
    errors,
    currentStep,
    validated,
    isSubmitting,
    submitError,
    setFormData,
    setErrors,
    setCurrentStep,
    setValidated,
    setIsSubmitting,
    setSubmitError,
    resetForm,
  } = useFormStore();

  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const [showFinalSuccessModal, setShowFinalSuccessModal] = useState(false);
  const [paymentReference, setPaymentReference] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [emailHtml, setEmailHtml] = useState("");
  const [processedEmailHtml, setProcessedEmailHtml] = useState("");

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);

  const { fields, payment } = event.registrable.formData;

  const initialFormData = useMemo(
    () => ({
      is_member: false,
      member_area_id: "",
      bed_option: "registration_only",
      baseAmount: event.registrable.formData.payment.amount,
      bedFeeAmount: 1500,
      ...fields.reduce((acc, field) => {
        const fieldName = field.label.toLowerCase().replace(/ /g, "_");
        acc[fieldName] = "";
        return acc;
      }, {}),
    }),
    [event.registrable.formData.payment.amount, fields]
  );

  const initialErrors = useMemo(
    () => ({
      is_member: "",
      member_area_id: "",
      bed_option: "",
      ...fields.reduce((acc, field) => {
        const fieldName = field.label.toLowerCase().replace(/ /g, "_");
        acc[fieldName] = "";
        return acc;
      }, {}),
    }),
    [fields]
  );

  useEffect(() => {
    resetForm(initialFormData, initialErrors);
  }, [resetForm, initialFormData, initialErrors]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    if (currentStep === 1) {
      fields.forEach((field) => {
        const fieldName = field.label.toLowerCase().replace(/ /g, "_");
        const value = formData[fieldName]?.trim();
        const fieldType = field.type === "email" ? "email" : field.type;
        const sanitizedValue = sanitizeInput(value, fieldType);

        if (!sanitizedValue) {
          valid = false;
          newErrors[
            fieldName
          ] = `Invalid characters detected in the ${field.label} field.`;
        }

        if (
          field.type === "email" &&
          value &&
          !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        ) {
          valid = false;
          newErrors[fieldName] = "Invalid email format.";
        }

        if (
          fieldName === "phone_number" &&
          value &&
          !/^\d{10,15}$/.test(value)
        ) {
          valid = false;
          newErrors[fieldName] =
            "Invalid phone number format. Use 10-15 digits.";
        }
      });

      if (
        !["registration_only", "registration_with_bed"].includes(
          formData.bed_option
        )
      ) {
        valid = false;
        newErrors.bed_option = "Please select a valid registration option.";
      }

      if (formData.is_member === undefined) {
        valid = false;
        newErrors.is_member = "Please select your membership status.";
      }

      if (formData.is_member && formData.member_area_id === "") {
        valid = false;
        newErrors.member_area_id = "Please select your area.";
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const inputValue = name === "is_member" ? checked : value;

    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleNext = () => {
    const isValid = validateForm();
    setValidated(true);

    if (isValid) {
      setCurrentStep(currentStep + 1);
      setSubmitError(null);
    } else {
      setSubmitError("Please fix the errors before proceeding.");
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
    setSubmitError(null);
  };

  const publicKey = "FLWPUBK_TEST-9514d17033f4e881a22aa0b706f225da-X";

  const handlePaymentSuccess = (response) => {
    setFormData((prevData) => ({
      ...prevData,
      paymentReference: response.transaction_id,
      paymentStatus: response.status,
      paymentAmount: response.amount,
      paymentCurrency: response.currency,
    }));

    setPaymentReference(response.transaction_id);
    setShowPaymentSuccessModal(true);
  };

  const handleConfirmRegistration = async () => {
    setIsSubmitting(true);
    setSubmitError(null);

    const registrationData = {
      ...formData,
      event_id: event.id,
      paymentReference: paymentReference,
    };

    try {
      const response = await fetch(
        "https://cgwc-youth-fellowship-backend-node.onrender.com/youthFellowship/v1/registration",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(registrationData),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Network response was not ok");
      }

      setSubmitMessage(data.message || "Registration successful!");
      setRegistrationNumber(data.registrationNumber || "");
      setEmailHtml(data.emailHtml || "");
      resetForm(initialFormData, initialErrors);
      setShowPaymentSuccessModal(false);
      setShowFinalSuccessModal(true);
    } catch (error) {
      setSubmitError(
        error.message ||
          "There was an error submitting the form. Please try again later."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePaymentClose = () => {
    alert("Payment was closed. If this was unintended, please try again.");
    setSubmitError("Payment was not completed. Please try again.");
  };

  const getTotalAmount = () =>
    formData.bed_option === "registration_with_bed"
      ? formData.baseAmount + formData.bedFeeAmount
      : formData.baseAmount;

  const phoneNumber = formData.phone_number || "";
  const customerName = `${formData.first_name || ""} ${
    formData.last_name || ""
  }`.trim();

  const handleCopyRegistrationNumber = () => {
    if (!registrationNumber) {
      alert("No registration number available to copy.");
      return;
    }
    navigator.clipboard.writeText(registrationNumber).then(
      () => {
        alert("Registration number copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
        alert("Failed to copy registration number. Please try again.");
      }
    );
  };

  const handleDownloadEmailAsPdf = async () => {
    setIsDownloading(true);
    setDownloadComplete(false);

    const node = document.getElementById("emailContentPreview");
    if (!node) {
      alert("Email content not available for download.");
      setIsDownloading(false);
      return;
    }

    try {
      const canvas = await html2canvas(node, { useCORS: true });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("RegistrationConfirmation.pdf");

      setDownloadComplete(true);
    } catch (error) {
      console.error("Failed to download PDF:", error);
      alert("Failed to download PDF. Please try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  const unescapedEmailHtml = he.decode(emailHtml);

  useEffect(() => {
    if (unescapedEmailHtml) {
      const styleRegex = /<style[^>]*>([\s\S]*?)<\/style>/i;
      const styleMatch = styleRegex.exec(unescapedEmailHtml);
      let styleContent = "";
      if (styleMatch && styleMatch[1]) {
        styleContent = styleMatch[1];

        const styleElement = document.createElement("style");
        styleElement.type = "text/css";
        styleElement.id = "emailStyles";
        styleElement.appendChild(document.createTextNode(styleContent));
        document.head.appendChild(styleElement);
      }

      const htmlWithoutStyle = unescapedEmailHtml.replace(styleRegex, "");
      setProcessedEmailHtml(htmlWithoutStyle);
    }

    return () => {
      const styleElement = document.getElementById("emailStyles");
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, [unescapedEmailHtml]);

  return (
    <>
      <Form noValidate validated={validated}>
        {submitError && <ErrorAlert errors={{ submitError }} />}

        {currentStep === 1 && (
          <StepOneForm
            formData={formData}
            errors={errors}
            handleChange={handleChange}
            handleNext={handleNext}
            isSubmitting={isSubmitting}
            fields={fields}
            memberAreas={memberAreas}
          />
        )}

        {currentStep === 2 && payment && payment.status && (
          <StepTwoForm
            getTotalAmount={getTotalAmount}
            bedOption={formData.bed_option}
            handleBack={handleBack}
            handlePaymentSuccess={handlePaymentSuccess}
            handlePaymentClose={handlePaymentClose}
            isSubmitting={isSubmitting}
            email={formData.email}
            phoneNumber={phoneNumber}
            customerName={customerName}
            publicKey={publicKey}
          />
        )}
      </Form>

      <Modal
        show={showPaymentSuccessModal}
        onHide={() => setShowPaymentSuccessModal(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#042556", color: "#fff" }}
        >
          <Modal.Title>Payment Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your payment was successful! Click "OK" to complete your
            registration.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleConfirmRegistration}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Processing...
              </>
            ) : (
              "OK"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showFinalSuccessModal}
        onHide={() => setShowFinalSuccessModal(false)}
        centered
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#042556", color: "#fff" }}
        >
          <Modal.Title>Registration Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{submitMessage}</p>
          {registrationNumber && (
            <>
              <div className="d-flex align-items-center mb-3">
                <strong>Registration Number: </strong>
                <span className="ms-2">{registrationNumber}</span>
                <Button
                  variant="link"
                  onClick={handleCopyRegistrationNumber}
                  className="ms-2 p-0"
                  aria-label="Copy Registration Number"
                >
                  <Clipboard size={18} />
                </Button>
              </div>
              <div
                id="emailContentPreview"
                style={{
                  position: "absolute",
                  left: "-9999px",
                  top: "-9999px",
                }}
                dangerouslySetInnerHTML={{ __html: processedEmailHtml }}
              ></div>
              <Button
                variant={downloadComplete ? "success" : "primary"}
                onClick={handleDownloadEmailAsPdf}
                className="me-2"
                aria-label="Download Confirmation as PDF"
                disabled={isDownloading}
              >
                {isDownloading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Downloading...
                  </>
                ) : downloadComplete ? (
                  <>
                    <span role="img" aria-label="check">
                      ✔️
                    </span>{" "}
                    Downloaded
                  </>
                ) : (
                  <>
                    <Download size={18} className="me-2" />
                    Download Confirmation as PDF
                  </>
                )}
              </Button>
            </>
          )}
          <p>
            Your registration and confirmation have been successfully processed.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowFinalSuccessModal(false);
              onClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EventRegistrationForm;
