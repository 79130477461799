// src/store/formStore.js
import { create } from "zustand";

const useFormStore = create((set) => ({
  formData: {},
  errors: {},
  currentStep: 1,
  validated: false,
  isSubmitting: false,
  submitSuccess: false,
  submitError: null,

  setFormData: (update) =>
    set((state) => ({
      formData:
        typeof update === "function"
          ? update(state.formData)
          : { ...state.formData, ...update },
    })),

  setErrors: (update) =>
    set((state) => ({
      errors:
        typeof update === "function"
          ? update(state.errors)
          : { ...state.errors, ...update },
    })),

  setCurrentStep: (step) => set({ currentStep: step }),

  setValidated: (status) => set({ validated: status }),

  setIsSubmitting: (status) => set({ isSubmitting: status }),

  setSubmitSuccess: (status) => set({ submitSuccess: status }),

  setSubmitError: (error) => set({ submitError: error }),

  resetForm: (initialFormData, initialErrors) =>
    set({
      formData: initialFormData,
      errors: initialErrors,
      validated: false,
      currentStep: 1,
      isSubmitting: false,
      submitSuccess: false,
      submitError: null,
    }),
}));

export default useFormStore;
