import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import imglogo from "../images/cgwc-logo.png";

const activities = [
  {
    title: "Morning Devotionals",
    icon: "bi-sunrise-fill",
    description: "Group gatherings for prayer, worship, and meditation to start the day.",
  },
  {
    title: "Bible Study Sessions",
    icon: "bi-book-fill",
    description: "In-depth studies and discussions on specific Bible verses, themes, or stories.",
  },
  {
    title: "Praise and Worship",
    icon: "bi-music-note-beamed",
    description: "A time for singing, dancing, and collective worship led by a music team.",
  },
  {
    title: "Personal Reflection Time",
    icon: "bi-journal-bookmark-fill",
    description: "Quiet moments for individuals to reflect, meditate, and journal.",
  },
  {
    title: "Testimony Sharing",
    icon: "bi-person-lines-fill",
    description: "Participants share personal stories or testimonies about their faith journey and life experiences.",
  },
  {
    title: "Prayer Walks",
    icon: "bi-map-fill",
    description: "Guided walks around the campgrounds focused on prayer and spiritual reflection.",
  },
  {
    title: "Evening Revival or Sermons",
    icon: "bi-megaphone-fill",
    description: "Inspirational sermons or messages delivered by camp leaders, pastors, or guest speakers.",
  },
  {
    title: "Attendance",
    icon: "bi-check-circle-fill",
    description: "Mark your attendance for this event to keep track of participation.",
  },
];

function CampActivities() {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const closeNavbar = () => {
    setIsNavbarCollapsed(true);
  };

  const handleGoBack = () => {
    // Navigate to the root path "/"
    navigate("/");

    // After a short delay, scroll to the features section on the LandingPage
    setTimeout(() => {
      window.location.hash = "#features"; // Set the hash to scroll to the "features" section
    }, 100); // Adjust delay as needed
  };

  return (
    <div>
      {/* Navbar with "Go Back" Button */}
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-white shadow-sm">
        <div className="container">
          <a className="navbar-brand" href="/" onClick={closeNavbar}>
            <img src={imglogo} alt="CGWC Logo" style={{ height: "40px" }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarNav"
            aria-expanded={!isNavbarCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`} id="navbarNav">
            <Button
              onClick={handleGoBack}
              className="ms-auto"
              style={{ color: "#042556", fontWeight: "bold", backgroundColor: "transparent", border: "none" }}
            >
              Go Back
            </Button>
          </div>
        </div>
      </nav>

      {/* Activities Section */}
      <section id="spiritual-activities" className="py-5 mt-5 bg-light">
        <Container>
          <h2 className="text-center text-primary mb-4">Spiritual and Reflective Activities</h2>
          <Row>
            {activities.map((activity, index) => (
              <Col xs={12} md={6} lg={4} className="mb-4" key={index}>
                <Card className="shadow-sm mb-3" style={{ borderRadius: "10px", overflow: "hidden" }}>
                  <Card.Body className="text-center">
                    <div className="icon-container mb-2">
                      <i
                        className={`${activity.icon}`}
                        style={{ fontSize: "2rem", color: "#042556" }}
                      ></i>
                    </div>
                    <Card.Title className="text-dark" style={{ fontSize: "1.1rem" }}>{activity.title}</Card.Title>
                    <Card.Text className="text-muted" style={{ fontSize: "0.9rem" }}>{activity.description}</Card.Text>

                    {/* "Learn More" Button as Anchor Tag */}
                    <a
                      href={`/activities/${activity.title}`}
                      className="btn"
                      style={{
                        backgroundColor: "#042556",
                        color: "#fff",
                        borderRadius: "5px",
                        padding: "6px 12px",
                        fontSize: "0.9rem",
                      }}
                    >
                      Learn More
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default CampActivities;
