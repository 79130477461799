import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import EventRegistrationForm from "./EventRegistrationForm";
import youthCamps from "../eventsimg/youthcamp2024.jpg"; // Image import

const eventsData = [
  {
    img: youthCamps,
    title: "CGWC YOUTH CAMP 2024",
    shortDescription:
      'In today\'s fast-paced world, the "Spirit of Age" often pulls us toward conformity, distractions, and compromise...',
    fullDescription:
      'In today\'s fast-paced world, the "Spirit of Age" often pulls us toward conformity, distractions, and compromise. The CGWC Youth Camp 2024 is an immersive experience aimed at inspiring youth to stay true to their beliefs and strengthen their bond with their community and faith through engaging activities, workshops, and discussions.',
    registrable: {
      status: true,
      formData: {
        fields: [
          { label: "First Name", type: "text" },
          { label: "Last Name", type: "text" },
          { label: "Email", type: "email" },
          { label: "Phone Number", type: "tel" },
        ],
        payment: { status: true, amount: 3000 },
      },
    },
  },
  // Add more events as needed
];

function Events() {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [expandedEventIndex, setExpandedEventIndex] = useState(null);

  const handleRegisterClick = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  const toggleDescription = (index) => {
    setExpandedEventIndex(expandedEventIndex === index ? null : index);
  };

  return (
    <section className="events-section py-5">
      <div className="container">
        <h2 className="text-center mb-4 text-primary">Upcoming Events</h2>
        <div className="row">
          {eventsData.map((event, index) => {
            const isExpanded = expandedEventIndex === index;
            const description = isExpanded
              ? event.fullDescription
              : event.shortDescription;

            return (
              <div className="col-md-4 mb-4" key={index}>
                <div className="card h-100 bg-light shadow-sm">
                  <img
                    src={event.img}
                    className="card-img-top"
                    alt={event.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title text-secondary">{event.title}</h5>
                    <p className="card-text">{description}</p>
                    <Button
                      style={{ backgroundColor: "#042556", color: "#fff", border: "none" }}
                      className="rounded-pill d-flex align-items-center"
                      onClick={() => toggleDescription(index)}
                    >
                      {isExpanded ? (
                        <>
                          Read Less <i className="bi bi-arrow-up ms-1"></i>
                        </>
                      ) : (
                        <>
                          Read More <i className="bi bi-arrow-down ms-1"></i>
                        </>
                      )}
                    </Button>
                    {event.registrable.status && (
                      <Button
                        style={{ backgroundColor: "#042556", color: "#fff", border: "none" }}
                        onClick={() => handleRegisterClick(event)}
                        className="mt-3"
                      >
                        Register Now
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Bootstrap Modal for Event Details and Registration */}
      {selectedEvent && (
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="text-primary">
              {selectedEvent.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={selectedEvent.img}
              alt={selectedEvent.title}
              className="img-fluid mb-3"
            />
            <p>{selectedEvent.fullDescription}</p>
            {selectedEvent.registrable.status && (
              <EventRegistrationForm
                event={selectedEvent}
                onClose={handleClose}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </section>
  );
}

export default Events;
