// src/services/sanitizeService.js
import DOMPurify from 'dompurify';

export function sanitizeInput(value, type) {
  if (typeof value !== 'string') return null;

  let sanitized;

  switch (type) {
    case 'text':
      sanitized = value.replace(/[^a-zA-Z0-9 ]/g, '');
      break;
    case 'email':
      sanitized = value.replace(/[^a-zA-Z0-9@._-]/g, '');
      break;
    case 'number':
      sanitized = value.replace(/[^0-9]/g, '');
      break;
    default:
      sanitized = value.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  return sanitized === value ? DOMPurify.sanitize(sanitized) : null;
}
