// src/components/ErrorAlert.jsx
import React from "react";
import { Alert } from "react-bootstrap";

function ErrorAlert({ errors }) {
  return (
    <Alert variant="danger" className="shadow-sm">
      <Alert.Heading className="mb-2">Error Notification</Alert.Heading>
      <p className="mb-1">Please fix the following issues:</p>
      <ul className="mb-0 ps-3">
        {Object.entries(errors).map(
          ([field, errorMsg]) =>
            errorMsg && (
              <li key={field} className="small text-danger">
                {errorMsg}
              </li>
            )
        )}
      </ul>
    </Alert>
  );
}

export default ErrorAlert;
