// src/services/area.js

// List of areas for CGWC Members with IDs
export const memberAreas = [
    { id: 1, name: "Agege" },
    { id: 2, name: "Iju/Iyesi" },
    { id: 3, name: "Abeokuta III" },
    { id: 4, name: "Odeda" },
    { id: 5, name: "Abeokuta IV" },
    { id: 6, name: "Ijoko" },
    { id: 7, name: "Ilogbo" },
    { id: 8, name: "Abeokuta I" },
    { id: 9, name: "Atan" },
    { id: 10, name: "Alagbado" },
    { id: 11, name: "Ifo" },
    { id: 12, name: "Ondo" },
    { id: 13, name: "Ikale" },
    { id: 14, name: "Sango I" },
    { id: 15, name: "Intl Hqts" },
    { id: 16, name: "Abeokuta II" },
    { id: 17, name: "Idologun" },
    { id: 18, name: "Ikotun" },
    { id: 19, name: "Oworonshoki" },
    { id: 20, name: "Owode Egba" },
    { id: 21, name: "Owode Yewa" },
    { id: 22, name: "Isheri" },
    { id: 23, name: "Ado odo" },
    { id: 24, name: "Idiroko" },
    { id: 25, name: "Ikoga" },
    { id: 26, name: "Imeko" },
    { id: 27, name: "Sango II" },
    { id: 28, name: "Ajebamidele" },
    { id: 29, name: "Ogbomosho" },
    { id: 30, name: "Ogunmakin" },
    { id: 31, name: "Tube" },
    { id: 32, name: "Mushin" },
    { id: 33, name: "Ijebu" },
    { id: 34, name: "Ilaro" },
    { id: 35, name: "Ajegunle" },
    { id: 36, name: "Oyo" },
    { id: 37, name: "Osun" },
    { id: 38, name: "Ilorin" },
    { id: 39, name: "Edo" },
    { id: 40, name: "Benin republic" },
  ];
  